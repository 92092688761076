import React, { useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import SuperLoader from '../components/SuperLoader';
import { ChainType, useAddressSearch } from '../hooks/useAddressSearch';
import { getAddress, storeSearchHistory } from '../lib/localStorage';
import { getShortenAddress, addressTypeToUrlName } from '../lib/addressHelpers';
import glass from '../assets/magnifying-glass.svg';

type Props = {
  className?: string;
};

const ComboboxStyled = styled(Combobox)`
  max-height: 4rem;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: inherit;
  align-items: center;
`;

const ComboboxInputStyled = styled(ComboboxInput)`
  max-height: 2rem;
  height: 100%;
  width: 100%;
  border: 0.1rem solid rgba(0, 0, 0, 0);
  border-radius: var(--border-radius);
  background: var(--secondary-bg-color);
  padding-left: 1rem;
  ::placeholder {
    color: var(--secondary-font-color);
  }
`;

const ComboboxPopoverStyled = styled(ComboboxPopover)`
  border-radius: var(--border-radius);
  margin-top: 0.3rem;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  max-width: 35rem;
`;

const Flex = styled.div`
  width: 100%;
  display: flex;
`;

const SearchIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: -30px;
  margin-top: 9px;
`;

const LoaderContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-left: -30px;
  margin-top: 6px;
`;

const AddressSearch: React.FC<Props> = ({ className, children }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const isSearchTermEmpty = searchTerm.trim() === '';
  const { addressSearchResults, loading } = useAddressSearch(
    searchTerm.trim().toLowerCase()
  );
  const history = useHistory();

  const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const onSelect = async (value: string) => {
    const [valueChain, valueType, valueAddress] = value.split(';');

    const searchResult = addressSearchResults.find(
      (result) =>
        result.network === valueChain &&
        result.type === valueType &&
        result.address === valueAddress
    );
    if (!searchResult) {
      return;
    }

    const searchHistoryEntry = getAddress(searchResult.address);
    if (searchHistoryEntry) {
      storeSearchHistory({
        name: searchHistoryEntry.name,
        address: searchTerm,
        type: searchResult.type,
        chain: searchResult.network.toLowerCase().trim() as ChainType,
        id: searchHistoryEntry.id,
      });
    } else {
      storeSearchHistory({
        name: getShortenAddress(searchTerm),
        address: searchTerm,
        type: searchResult.type,
        chain: searchResult.network.toLowerCase().trim() as ChainType,
        id: searchResult.address,
      });
    }

    const entityName = addressTypeToUrlName(searchResult.type);
    history.push(
      `/${searchResult.network.toLowerCase().trim()}/${entityName}/${
        searchResult.address
      }`
    );
  };
  return (
    <SearchContainer className={className}>
      {children}
      <Flex>
        <ComboboxStyled
          aria-label="Addresses"
          onSelect={onSelect}
          openOnFocus={!loading && !isSearchTermEmpty}
        >
          <ComboboxInputStyled
            data-cy={'search-field'}
            onChange={handleSearchTermChange}
            placeholder="Search any address"
            autocomplete={false}
          />
          {addressSearchResults && !loading && (
            <ComboboxPopoverStyled className="shadow-popup">
              {addressSearchResults.length > 0 ? (
                <ComboboxList>
                  {addressSearchResults.map((result) => {
                    const str = `${result.type} on ${result.network} ${result.shortDescription}`;
                    return (
                      <ComboboxOption
                        data-cy={'search-result'}
                        key={str}
                        value={`${result.network};${result.type};${result.address}`}
                      >
                        {str}
                      </ComboboxOption>
                    );
                  })}
                </ComboboxList>
              ) : (
                <span
                  data-cy={'no-results'}
                  style={{ display: 'block', margin: 8 }}
                >
                  No results found
                </span>
              )}
            </ComboboxPopoverStyled>
          )}
        </ComboboxStyled>
        {loading ? (
          <LoaderContainer>
            <SuperLoader />
          </LoaderContainer>
        ) : (
          <SearchIcon src={glass} />
        )}
      </Flex>
    </SearchContainer>
  );
};

export default AddressSearch;
