import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';

const Copy = styled.span`
  cursor: default;
  text-decoration: underline;
`;

const FitContent = styled.a`
  width: fit-content;
`;

export interface Props {
  symbol?: string;
  children: any;
}

const FormattedNumber: React.FC<Props> = ({ symbol, children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleClick = () => {
    navigator.clipboard.writeText(children);
    enqueueSnackbar('Copied', {
      anchorOrigin: { horizontal: 'right', vertical: 'top' },
      preventDuplicate: true,
      variant: 'success',
      autoHideDuration: 1000,
    });
  };

  return (
    <>
      <FitContent
        data-cy="formatted-number"
        data-tip={children}
        onClick={handleClick}
      >
        <Copy>{parseFloat(children).toFixed(5)}</Copy>
      </FitContent>
      <ReactTooltip place="top" effect="solid" />
      {symbol && <span> {symbol}</span>}
    </>
  );
};

export default FormattedNumber;
