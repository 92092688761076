import { EntityType, ChainType } from '../hooks/useAddressSearch';
import { getAddress, buildAddressKey } from '../lib/localStorage';

export const getAddressName = (
  address: string,
  chainName: ChainType
): string => {
  return (
    getAddress(buildAddressKey(address, chainName))?.name ||
    getShortenAddress(address)
  );
};

export const getShortenAddress = (address: string): string => {
  return `${address.substr(0, 4)}...${address.substr(
    address.length - 4,
    address.length
  )}`;
};

export const addressTypeToUrlName = (addressType: EntityType): string => {
  if (addressType.trim() === 'Token') {
    return 'supertoken';
  }

  if (addressType.trim() === 'Account') {
    return 'account';
  }
  return '';
};

export function getLastElement<T>(arr: T[]): T | null {
  if (arr.length === 0) {
    return null;
  }
  return arr[arr.length - 1];
}

export function getFirstElement<T>(arr: T[]): T | null {
  if (arr.length === 0) {
    return null;
  }
  return arr[0];
}
