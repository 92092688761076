import React, {
  FocusEvent,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Address, storeAddress } from '../lib/localStorage';
import editIcon from '../assets/edit.svg';
import saveIcon from '../assets/save.svg';

type Props = {
  address: Address;
};

type EditButtonProps = {
  edit: boolean;
  setEdit: (arg0: boolean) => void;
  save?: () => void;
};

const NameInput = styled.input`
  max-width: 20rem;
  width: 100%;
  font-size: 1rem;
`;

const EditImage = styled.img`
  margin-left: 0.4rem;
  height: 16px;
  width: 16px;
  cursor: pointer;
`;

const EditButton: React.FC<EditButtonProps> = ({ edit, setEdit, save }) => {
  const onMouseDown = (): void => {
    if (edit && save) {
      save();
    }
    setEdit(!edit);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLImageElement>): void => {
    if (e.key === 'Enter') {
      setEdit(!edit);
    }
  };

  return (
    <>
      {edit ? (
        <EditImage
          data-cy={'save-address-button'}
          role="button"
          tabIndex={0}
          src={saveIcon}
          alt="Save address name"
          onMouseDown={onMouseDown}
          onKeyDown={onKeyDown}
        />
      ) : (
        <EditImage
          data-cy={'edit-address-button'}
          role="button"
          tabIndex={0}
          src={editIcon}
          alt="Edit address name"
          onMouseDown={onMouseDown}
          onKeyDown={onKeyDown}
        />
      )}
    </>
  );
};

const EditName: React.FC<Props> = ({ address }) => {
  const [edit, setEdit] = useState(false);
  const [internalName, setInternalName] = useState(address.name);
  const [previousInternalName, setPreviousInternalName] = useState(
    address.name
  );
  const nameInput = useRef(null);

  const save = () => {
    setEdit(!edit);
    const updatedAddress = { ...address, name: internalName };
    setPreviousInternalName(internalName);
    storeAddress(updatedAddress);
  };

  const onEnter = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      save();
    }
    if (e.key === 'Escape') {
      setInternalName(previousInternalName);
      setEdit(!edit);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInternalName(e.target.value);
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setInternalName(previousInternalName);
    setEdit(!edit);
  };

  useEffect(() => {
    if (edit) {
      if (nameInput !== null && 'current' in nameInput) {
        if (nameInput.current !== null) {
          const ref = nameInput as any;
          ref.current.focus();
        }
      }
    }
  }, [edit]);

  useEffect(() => {
    setInternalName(address.name);
  }, [address.name]);

  return (
    <>
      {edit ? (
        <>
          <NameInput
            data-cy={'name-input'}
            value={internalName}
            ref={nameInput}
            onChange={onChange}
            onKeyDown={onEnter}
            onBlur={onBlur}
          />
          <EditButton edit={edit} setEdit={setEdit} save={save} />
        </>
      ) : (
        <>
          <NavLink
            data-cy={'address-link'}
            to={`/${address.chain}/${
              address.type === 'Token' ? 'supertoken' : 'account'
            }/${address.address}`}
          >
            {internalName}
          </NavLink>
          <EditButton edit={edit} setEdit={setEdit} />
        </>
      )}
    </>
  );
};

export default EditName;
