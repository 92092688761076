import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  cursor: pointer;
  :hover {
    path {
      fill: #67608a;
    }
  }
`;

const ExpanderButton: React.FC = () => {
  return (
    <StyledSvg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4852 16.7207C11.9159 17.1514 12.7014 17.1514 13.1322 16.7207L17.6931 12.1598C18.1492 11.7037
          18.1492 10.9689 17.6931 10.5128C17.237 10.0567 16.5022 10.0567 16.0461 10.5128L12.3214 14.2375L8.59662 10.5128C8.36858 10.2847 8.06452 10.158 7.76046 10.158C7.4564 10.158 7.15234 10.2847 6.92429 10.5128C6.4682 10.9689 6.4682 11.7037 6.92429 12.1598L11.4852 16.7207Z"
        fill="#8B83BA"
      />
      <path
        d="M12.3214 24.981C18.96 24.981 24.3571 19.5839 24.3571 12.9452C24.3571 6.3066 18.96 0.909531 12.3214
          0.909531C5.68271 0.909531 0.285645 6.3066 0.285645 12.9452C0.285645 19.5839 5.68271 24.981 12.3214 24.981ZM12.3214 3.24066C17.6678 3.24066 22.0259 7.59885 22.0259 12.9452C22.0259 18.2916 17.6678 22.6498 12.3214 22.6498C6.97497 22.6498 2.61677 18.2916 2.61677 12.9452C2.61677 7.59885 6.97497 3.24066 12.3214 3.24066Z"
        fill="#8B83BA"
      />
    </StyledSvg>
  );
};

export default ExpanderButton;
