import _ from 'lodash';
import { Contract } from 'ethers';
import { getProviderByChainName } from '../lib/networks';

export const getErc20TokenContractMemoized = _.memoize(
  async (
    networkName: string,
    tokenAddress: string
  ): Promise<
    Contract & {
      totalSupply(): Promise<number>;
      balanceOf(address: string): Promise<number>;
    }
  > => {
    const abi = [
      'function totalSupply() view returns (uint256)',
      'function balanceOf(address owner) view returns (uint256)',
    ];
    const provider = await getProviderByChainName(networkName);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return new Contract(tokenAddress, abi, provider);
  },
  (...args) => _.values(args).join('_')
);
