import React from 'react';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const SuperLoader: React.FC = () => (
  <Center>
    <Loader type="Oval" color="rgb(16, 187, 52)" height={25} width={25} />
  </Center>
);

export default SuperLoader;
