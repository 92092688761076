import { EntityType, ChainType } from '../hooks/useAddressSearch';
const FAVORITE_KEY = 'favorite';
const ADDRESS_KEY = 'address';

type Addresses = {
  [key: string]: Address;
};

export type Address = {
  name: string;
  address: string;
  type: EntityType;
  chain: ChainType;
  id: string;
};

export const getSearchHistory = (): Addresses => {
  const serializedAddresses = localStorage.getItem(ADDRESS_KEY);
  if (serializedAddresses) {
    return JSON.parse(serializedAddresses);
  }
  return {};
};

export const getHistoryAddress = (address: string, chain: string): Address => {
  const addresses = getSearchHistory();
  const existingAddress =
    addresses[buildAddressKey(address, chain as ChainType)];
  if (existingAddress) {
    return existingAddress;
  }
  return {
    name: `${address.substr(0, 4)}...${address.substr(
      address.length - 4,
      address.length
    )}`,
    address: address,
    type: 'Account',
    chain: chain as ChainType,
    id: address,
  };
};

export const storeSearchHistory = (address: Address): void => {
  const addresses = getSearchHistory();
  addresses[buildAddressKey(address.address, address.chain)] = address;
  localStorage.setItem(ADDRESS_KEY, JSON.stringify(addresses));
};

export const getAddresses = (): Addresses => {
  const serializedFavorites = localStorage.getItem(FAVORITE_KEY);
  if (serializedFavorites) {
    return JSON.parse(serializedFavorites);
  }
  return {};
};

export const getAddress = (address: string): Address | null => {
  const favorites = getAddresses();
  const existingFavorite = favorites[address];
  if (existingFavorite) {
    return existingFavorite;
  }
  return null;
};

export const storeAddress = (favorite: Address): void => {
  const favorites = getAddresses();
  favorites[buildAddressKey(favorite.address, favorite.chain)] = favorite;
  localStorage.setItem(FAVORITE_KEY, JSON.stringify(favorites));
};

export const removeAddress = (address: string, chain: ChainType): void => {
  const favorites = getAddresses();
  delete favorites[buildAddressKey(address, chain)];
  localStorage.setItem(FAVORITE_KEY, JSON.stringify(favorites));
};

export const buildAddressKey = (address: string, chain: ChainType): string => {
  return `${address.toLowerCase()}-${chain.toLowerCase()}`;
};
