import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, NavLink } from 'react-router-dom';
import { utils, BigNumber } from 'ethers';
import styled from 'styled-components';

import {
  fetchIndexSubscriptions,
  fetchIndexes,
  fetchAccountStreams,
  fetchAccountWithStreams,
} from '../graphql';
import { useStreamContext } from '../context/StreamContext';
import { getNetworkByChainId, getNetworkByName } from '../lib/networks';
import { getFormattedCount, getExpandedClassname } from '../lib/displayHelpers';
import { getLastElement } from '../lib/addressHelpers';
import FormattedNumber from '../components/FormattedNumber';

import ExpanderButton from '../components/ExpanderButton';
import up from '../assets/up.svg';
import down from '../assets/down.svg';
import { getErc20TokenContractMemoized } from '../utils/erc20';
import SuperLoader from './SuperLoader';

type AccountParams = {
  address: string;
  chainName: string;
};

export const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid #e0e8f8;
  border-radius: var(--border-radius);
`;

export const Tr = styled.tr`
  height: 1rem;
  &:not(:first-of-type) {
    border-top: 1px solid #e0e8f8;
  }
  &.child {
    background: #ddd;
    border-top: none;
  }
`;

export const Td = styled.td`
  padding: 1rem;
  text-align: left;
  font-size: 1rem;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Expander = styled.button`
  transform: rotate(-90deg);
  border: none;
  background: none;

  &&.open {
    transition-duration: 0.25s;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(0deg);
  }
`;

const AddressCol = styled.td`
  min-width: 140px;
  padding: 1rem;
`;

const DateCol = styled.td`
  min-width: 190px;
`;

type Props = {
  name: string;
  accountAddress: string;
  chainId: string;
  tokenId: string;
  tokenSymbol: string;
};

export const DataRow: React.FC<Props> = ({
  name,
  accountAddress,
  chainId,
  tokenId,
  tokenSymbol,
  children,
}) => {
  const { flowRateGranularity } = useStreamContext();
  const [expanded, setExpanded] = useState(false);
  const [netFlow, setNetFlow] = useState('');
  const [streamsOwned, setStreamsOwned] = useState(0);
  const [streamsReceived, setStreamsReceived] = useState(0);
  const [streamsExist, setStreamsExist] = useState(false);
  const { chainName } = useParams<AccountParams>();
  const [balance, setBalance] = useState<number | undefined>(undefined);

  useEffect(() => {
    const network = getNetworkByChainId(chainId);
    getErc20TokenContractMemoized(network.name, tokenId)
      .then((tokenContract) => tokenContract.balanceOf(accountAddress))
      .then((b) => {
        setBalance(b);
      })
      .catch(console.error);
  }, [chainId, tokenId, accountAddress]);

  const expand = () => {
    setExpanded(!expanded);
  };

  const location = useLocation();
  useEffect(() => {
    resetState();
  }, [location]);

  const resetState = () => {
    setExpanded(false);
    setNetFlow('');
    setStreamsOwned(0);
    setStreamsReceived(0);
  };

  const calculateSum = useCallback(async () => {
    const currentTime = Math.round(new Date().getTime() / 1000).toString();
    let streams = await fetchAccountStreams(
      accountAddress,
      chainId,
      tokenId,
      currentTime,
      currentTime,
      true,
      false,
      1000,
      true
    );
    let sum = BigNumber.from(0);

    // Max net of 100,000 active streams
    // as a final backstop for infinite loops
    let maxIter = 0;
    do {
      maxIter++;
      for (let i = 0; i < streams.length; i++) {
        const flow = streams[i];
        if (flow.sender.id.toLowerCase() === accountAddress.toLowerCase()) {
          sum = sum.sub(BigNumber.from(flow.currentFlowRate));
          continue;
        }
        sum = sum.add(BigNumber.from(flow.currentFlowRate));
      }
      const el = getLastElement(streams);
      if (el) {
        streams = await fetchAccountStreams(
          accountAddress,
          chainId,
          tokenId,
          el.createdAtTimestamp.toString(),
          el.createdAtTimestamp.toString(),
          true,
          false,
          1000,
          true
        );
      }
    } while (streams.length >= 1000 && maxIter <= 100);
    setNetFlow(utils.formatEther(sum.mul(BigNumber.from(flowRateGranularity))));
  }, [chainId, tokenId, accountAddress, flowRateGranularity]);

  // Calculate sums for balance

  useEffect(() => {
    calculateSum();
  }, [calculateSum]);

  useEffect(() => {
    const getStreamsExist = async () => {
      const streams = await fetchAccountStreams(
        accountAddress,
        chainId,
        tokenId,
        '0',
        '0',
        false,
        false,
        1
      );
      setStreamsExist(streams.length !== 0);
    };
    getStreamsExist();
  }, [accountAddress, chainId, tokenId]);

  useEffect(() => {
    const getAccountWithStreams = async () => {
      const currentTime = Math.round(new Date().getTime() / 1000).toString();
      const account = await fetchAccountWithStreams(
        accountAddress,
        chainId,
        tokenId,
        currentTime,
        currentTime,
        true,
        false,
        true,
        1000
      );
      if (account) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setStreamsOwned(account.streamsOwned!.length);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setStreamsReceived(account.streamsReceived!.length);
      }
    };
    getAccountWithStreams();
  }, [chainId, tokenId, accountAddress]);

  return (
    <React.Fragment>
      <Tr data-cy={'token-entry'} key="row">
        {streamsExist ? (
          <AddressCol>
            <Expander
              data-cy={'expander-button'}
              className={expanded ? 'open' : ''}
              onClick={expand}
            >
              <ExpanderButton />
            </Expander>
          </AddressCol>
        ) : (
          <Td></Td>
        )}
        <Td>
          <span>
            <NavLink
              data-cy={'token-name'}
              to={`/${chainName}/supertoken/${tokenId}`}
            >
              {name}
            </NavLink>
          </span>
        </Td>
        <Td data-cy={'token-netflow'}>
          <FormattedNumber>{netFlow}</FormattedNumber>
        </Td>
        <Td data-cy={'ongoing-streams'}>
          <span>CFAv1: {getFormattedCount(streamsReceived)}</span>
          <img src={up} /> {getFormattedCount(streamsOwned)}
          <img src={down} />
        </Td>
        <DateCol>
          <FlexCol data-cy={'token-balance-summary'}>
            {balance === undefined ? (
              <SuperLoader />
            ) : (
              <FormattedNumber symbol={tokenSymbol}>
                {utils.formatEther(BigNumber.from(balance))}
              </FormattedNumber>
            )}
          </FlexCol>
        </DateCol>
      </Tr>
      {expanded && <React.Fragment>{children}</React.Fragment>}
    </React.Fragment>
  );
};

export const IdaRow: React.FC = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const [idaCount, setIndexSubscriptionCount] = useState(0);
  const { address, chainName } = useParams<AccountParams>();

  const expand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const fetchIdaCount = async () => {
      const chain = getNetworkByName(chainName);
      if (chain) {
        const indexSubscriptions = await fetchIndexSubscriptions(
          address,
          chain.id
        );
        setIndexSubscriptionCount(indexSubscriptions.length);
      }
    };
    fetchIdaCount();
  }, [address, chainName]);

  return (
    <React.Fragment>
      <Tr key="row">
        <Td>
          <Expander className={getExpandedClassname(expanded)} onClick={expand}>
            <ExpanderButton />
          </Expander>
        </Td>
        <Td>
          <FlexCol>
            <span>IDA</span>
          </FlexCol>
        </Td>
        <Td>
          <span>Subscriptions: {getFormattedCount(idaCount)}</span>
        </Td>
        <Td></Td>
      </Tr>
      {expanded && <React.Fragment>{children}</React.Fragment>}
    </React.Fragment>
  );
};

export const IdaSubscribedRow: React.FC = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  const expand = () => {
    setExpanded(!expanded);
  };

  return (
    <React.Fragment>
      <Tr key="row">
        <Td>
          <Expander className={getExpandedClassname(expanded)} onClick={expand}>
            <ExpanderButton />
          </Expander>
        </Td>
        <Td>
          <FlexCol>
            <span>Approved Subscriptions</span>
          </FlexCol>
        </Td>
        <Td></Td>
        <Td></Td>
      </Tr>
      {expanded && <React.Fragment>{children}</React.Fragment>}
    </React.Fragment>
  );
};

export const IdaUnsubscribedRow: React.FC = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  const expand = () => {
    setExpanded(!expanded);
  };

  return (
    <React.Fragment>
      <Tr key="row">
        <Td>
          <Expander className={getExpandedClassname(expanded)} onClick={expand}>
            <ExpanderButton />
          </Expander>
        </Td>
        <Td>
          <FlexCol>
            <span>Unapproved Subscriptions</span>
          </FlexCol>
        </Td>
        <Td></Td>
        <Td></Td>
      </Tr>
      {expanded && <React.Fragment>{children}</React.Fragment>}
    </React.Fragment>
  );
};

export const PublisherRow: React.FC = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const [publisherCount, setPublisherCount] = useState(0);
  const { address, chainName } = useParams<AccountParams>();

  const expand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const fetchIdaCount = async () => {
      const chain = getNetworkByName(chainName);
      if (chain) {
        const idas = await fetchIndexes(address, chain.id, '', true, 1000);
        setPublisherCount(idas.length);
      }
    };
    fetchIdaCount();
  }, [address, chainName]);

  return (
    <React.Fragment>
      {publisherCount ? (
        <>
          <Tr key="row">
            <Td>
              <Expander
                className={getExpandedClassname(expanded)}
                onClick={expand}
              >
                <ExpanderButton />
              </Expander>
            </Td>
            <Td>
              <FlexCol>
                <span>Published IDA</span>
              </FlexCol>
            </Td>
            <Td>
              <span>Indices: {getFormattedCount(publisherCount)}</span>
            </Td>
            <Td></Td>
          </Tr>
          {expanded && <React.Fragment>{children}</React.Fragment>}
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
